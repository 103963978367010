body {
  font-family: "Futura Bk";
  width: 100%;
  height: 100%;
  color: #f6f6f6;
  font-size: 1.25rem;
}

img {
  width: 100%;
}

.content {
  .content__title {
    padding-left: 0;
    padding-right: 0;
  }
}

.round-button {
  border-radius: 2rem;
}

.btn-outline-theme {
  color: #4d6979;
}

.cursor-pointer {
  cursor: pointer;
}

section {
  .stats {
    .stat__info {
      font-size: 3rem;
    }

    i {
      font-size: 4rem;
    }
  }
}

form {
  .form-group {
    .checkbox-char {
      label {
        font-size: 0;
      }

      &.active {
        label {
          background-color: #f0f6f9;

          &:before {
            opacity: 1;
          }
        }
      }
    }
  }
}

/*** GENERAL COLOR STYLES ***/

.colors {
  &.white {
    color: #f6f6f6;
  }

  &.gray {
    color: rgba(246, 246, 246, 0.7);
  }

  &.remark-gray {
    color: #9a9a9a;
  }

  &.input-gray {
    color: #1d1d1d;
  }

  &.bg-gray {
    background-color: rgba(246, 246, 246, 0.6);
  }

  &.gray-70 {
    color: rgba(29, 29, 29, 0.7);
  }

  &.gray-50 {
    color: rgba(29, 29, 29, 0.5);
  }
}

/*** GENERAL FONT STYLES ***/

.fonts {
  &.font-48 {
    font-size: 3rem;
  }

  &.font-40 {
    font-size: 2.5rem;
  }

  &.font-36 {
    font-size: 2.25rem;
  }

  &.font-32 {
    font-size: 2rem;
  }

  &.font-28 {
    font-size: 1.75rem;
  }

  &.font-24 {
    font-size: 1.5rem;
  }

  &.font-22 {
    font-size: 1.375rem;
  }

  &.font-20 {
    font-size: 1.25rem;
  }

  &.font-18 {
    font-size: 1.125rem;
  }

  &.font-16 {
    font-size: 1rem;
  }

  &.font-14 {
    font-size: 0.875rem;
  }

  &.font-12 {
    font-size: 0.75rem;
  }

  &.font-10 {
    font-size: 0.625rem;
  }

  &.weight-100 {
    font-weight: 100;
  }

  &.weight-300 {
    font-weight: 300;
  }

  &.weight-400 {
    font-weight: 400;
  }

  &.weight-500 {
    font-weight: 500;
  }

  &.weight-700 {
    font-weight: 700;
  }

  &.weight-900 {
    font-weight: 900;
  }

  &.line-height-100 {
    line-height: 100%;
  }

  &.line-height-120 {
    line-height: 120%;
  }

  &.line-height-130 {
    line-height: 130%;
  }

  &.line-height-140 {
    line-height: 140%;
  }

  &.line-height-150 {
    line-height: 150%;
  }

  &.line-height-160 {
    line-height: 160%;
  }

  &.line-height-170 {
    line-height: 170%;
  }

  &.line-height-180 {
    line-height: 180%;
  }

  &.line-height-190 {
    line-height: 190%;
  }

  &.line-height-200 {
    line-height: 200%;
  }
}

/*** GENERAL BUTTON STYLES ***/

.button {
  &.btn-sm {
    padding: 0.3rem 0.7rem;
  }

  &.round-button {
    border-radius: 2rem;
  }

  &.btn-outline-theme {
    color: #4d6979;
  }

  &.btn-outline-primary {
    a {
      color: #27a4fb;

      &:hover {
        color: #fff;
      }
    }

    &:hover {
      a {
        color: #fff;
      }
    }
  }
}

.simplebar-track {
  .simplebar-scrollbar:before {
    background-color: #c2c2c2;
  }
}

#page-container {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
}

#main-container {
  margin-left: 5%;
  margin-right: 5%;
  min-height: 80vh;
  padding: 0;

  .page {
    .heading {
      .icon {
        img {
          width: 39px;
        }
      }
    }
  }
}

.app-text-field-container {
  background: rgba(246, 246, 246, 1);
  opacity: 0.8;
  border-radius: 24px;
  padding: 8px;
}

.opacity-half {
  opacity: 0.7 !important;
}

.app-text-field,
.app-text-field:focus {
  font-size: 24px;
  border: none;
  outline: none;
  box-shadow: none !important;
  background: transparent;
  border-bottom: 1px solid white;
}

.loader-container,
.not-found-container {
  width: auto;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page {
  padding: 1.75rem 0;
}

.menu {
  display: none;

  &.active {
    display: block;
  }
}

.success-modal {
  text-align: center;

  .logo {
    img {
      max-width: 300px;
    }
  }

  .icon {
    padding: 2rem 0;

    img {
      max-width: 150px;
    }
  }
}

.bg-theme-main {
  // background: linear-gradient(180deg, #00ae9e 0%, #0f749b 100%);
  background: rgba(214, 233, 238, 1) !important;
}

.bg-theme-main-blue {
  background: rgba(16, 117, 157, 1);
}

.icon {
  width: 24px;
  height: 24px;
}

.text-theme-blue {
  color: rgba(16, 117, 157, 1) !important;
}

.app-btn,
.app-btn:focus {
  border: none;
  box-shadow: none;
  height: 100%;
  min-width: 100px;
}

.rounded-right-5 {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.process-img {
  width: 50%;

  @media (max-width: 991.98px) {
    width: 100%;
  }
}
