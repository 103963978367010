#page-container
{
    #modal-overlay
    {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
        background-color: rgba(0, 0, 0, .5);
        transform: translateY(-100%);
        opacity: 0;
        transition: opacity .28s ease-out;
        z-index:1035;

        &.show
        {
            transform: translateY(0);
            opacity: 1;
        }
    }

    #page-overlay
    {
        &.show
        {
            transform: translateY(0);
            opacity: 1;
        }
    }
}